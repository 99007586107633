/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Overlay = ({ isActive, children, ...props }) => {
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: ${isActive ? 'all' : 'none'};
        opacity: ${isActive ? 1 : 0};
        transition: all linear 0.1s;
        transform: scale(${isActive ? 1 : 1.3});
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default Overlay;
