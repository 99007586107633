/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Layout = ({ children, ...props }) => {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      `}
      {...props}
    >
      <div
        css={css`
          max-width: calc(320em / 16);
          flex: 0 0 100%;
          position: relative;
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
